document.addEventListener("DOMContentLoaded", () => {
  const followCtaEl = document.querySelector(
    '[qid="profile-page-follow-link"]'
  ) as HTMLElement;
  const bodyBlockEl = document.querySelector('[qid="profile-post-area"]');
  if (!followCtaEl || !bodyBlockEl) {
    throw new Error("Could not find required elements");
  }

  bodyBlockEl.addEventListener("click", (event) => {
    const targetEl = event.target as HTMLElement;
    if (
      targetEl &&
      targetEl.matches("a") &&
      targetEl.matches(".js-follow-first")
    ) {
      event.preventDefault();
      followCtaEl.click();
    }
  });

  followCtaEl.addEventListener("click", () => {
    const firstFollowerResultEls = document.querySelectorAll(
      ".first-follow-result"
    );
    firstFollowerResultEls.forEach((resultEl) => {
      resultEl.classList.toggle("hidden");
    });

    const followFirstCtaEls = document.querySelectorAll(".js-follow-first");
    followFirstCtaEls.forEach((ctaEl) => {
      ctaEl.classList.toggle("hidden");
    });
  });
});
